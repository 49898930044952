/* src/index.css */

:root {
  --primary-main: #FBA22E;
  --secondary-main: #1976d2; /* Azul MUI padrão */
  --background-default: #f5f5f5;
  --background-paper: #ffffff;
  --text-primary: #333333;
  --text-secondary: #777777;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--background-default);
  font-family: 'Roboto', sans-serif;
}

@media (max-width: 600px) {
  .tweetbox-container, .tweet, .login-box, .register-box, .profile-container {
    padding: 16px;
  }

  .tweet-avatar, .tweetbox-avatar, .profile-avatar {
    width: 35px;
    height: 35px;
  }

  .tweet-actions {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
}
