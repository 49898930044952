/* src/pages/ProfilePage.css */

.profile-container {
  max-width: 600px;
  margin: 0 auto;
  border-left: 1px solid #e6ecf0;
  border-right: 1px solid #e6ecf0;
}

.profile-header {
  padding: 12px 16px;
  border-bottom: 1px solid #e6ecf0;
  position: relative;
  text-align: center;
}

.profile-avatar {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin-bottom: 8px;
}

.profile-username {
  font-size: 20px;
  font-weight: bold;
  color: #14171a;
}

.profile-followers {
  color: #657786;
  font-size: 15px;
}

.profile-action-button {
  position: absolute;
  top: 12px;
  right: 16px;
  background-color: #1da1f2;
  color: #fff;
  border: none;
  border-radius: 9999px;
  padding: 8px 16px;
  font-weight: bold;
  cursor: pointer;
}

.profile-action-button:hover {
  background-color: #1a91da;
}

.profile-uais {
  margin-top: 8px;
}
